


var SERVER_URL: string = '';
export let LAMBDA_COMBO_URL: string = '';
console.log('Origin URL: ', window.location.origin)
// local
if (window.location.origin.indexOf('localhost') !== -1 || window.location.origin.indexOf('http://34.195.227.17') !== -1) {
    SERVER_URL = 'http://localhost:5005';
    LAMBDA_COMBO_URL = 'http://localhost:5005';
    // SERVER_URL = 'https://api.roobetpicksstaging.com';
    // LAMBDA_COMBO_URL = 'https://nlc.roobetpicksstaging.com';
    // SERVER_URL = 'https://api.roobetpicks.com';
    console.log(`Hitting local backend, SERVER_URL: ${SERVER_URL}`)
// prod
} else if (window.location.origin.indexOf('https://www.admin.roobetpicks.com') !== -1 || window.location.origin.indexOf('https://admin.roobetpicks.com') !== -1) {
    SERVER_URL = 'https://api.roobetpicks.com';
    LAMBDA_COMBO_URL = 'https://nlc.roobetpicks.com';
    console.log(`Hitting production backend, SERVER_URL: ${SERVER_URL}`)
// staging
} else if (window.location.origin.indexOf('https://www.admin.roobetpicksstaging.com') !== -1 || window.location.origin.indexOf('https://admin.roobetpicksstaging.com') !== -1) {
    SERVER_URL = 'https://api.roobetpicksstaging.com';
    LAMBDA_COMBO_URL = 'https://nlc.roobetpicksstaging.com';
    console.log(`Hitting staging backend, SERVER_URL: ${SERVER_URL}`)
// no config found
} else {
    console.log('Invalid/Unrecognized origin URL: ', window.location.origin)
}


export let SERVER_NAME = SERVER_URL;
//export let STAGING_ENVIRONMENT_URL = 'https://admin.roobetpicksstaging.com';
export let STAGING_ENVIRONMENT_URL = 'http://localhost:5000/';
export let DateFormat = "YYYY-MM-DD";
export let DateShowFormat = "yyyy-MM-dd";
export let datePickerStartDateOpt = {
    startDate: new Date(),
    autoclose: true,
    todayBtn: 'linked',
    todayHighlight: true,
    assumeNearbyYear: true,
    format: 'M, dd yyyy'
}
export let APIS = {
    "createPropsWithCsv": "/createPropsWithCsv",
    "deactivatePropsWithCsv": "/deactivatePropsWithCsv",
    "contestListingJava": "/contests/showContests",
    "contestOrder": "/contests/updateContestOrder",
    "contestListing": "/contests/getUpcomingContests",
    "historyContestListingArchive": "/adminContest/get-admin-contest-history-archive",
    "liveContestListing": "/adminContest/live",
    "login": "/admin/login",
    "createContest": "/admin/contest",
    "createContestSimilars": "/admin/contest-similars",
    "newLandingPage": "/admin/newLandingPage",
    "fetchLandingPages": "/admin/fetchLandingPages",
    "newPromoCode": "/admin/newPromoCode",
    "eventsListing": "/adminContest/fetchEvents",
    "playerListing": "/adminContest/fetchTeamPlayers",
    "esportsPlayerListing": "/adminContest/fetchEsportsTeamPlayers",
    "lotteryPlayerListing": "/adminContest/fetchLotteryEventPlayers",
    "pubgPlayerListing": "/adminContest/fetchPubgEventPlayers",
    "golfPlayerListing": "/adminContest/fetchGolfEventPlayers",
    "availableProps": "/contests/availableProps",
    "propParameter": "/prop/parameter",
    "logout": "/logout",
    "contestCreate": "/prop",
    "propCreate": "/prop/single",
    "fetchProps": "/prop",
    "deactivateProp": "/prop/deactivateProp",
    "disableProp": "/prop/disableProp",
    "reactivateProp": "/prop/reactivateProp",
    "reenableProp": "/prop/reenableProp",
    "fetchPropsLive": "/prop/fetchPropsLive",
    "fetchPropsHistory": "/prop/fetchPropsHistory",
    "fetchNewUsers": "/prop/fetchNewUsers",
    "fetchFirstDeposits": "/prop/fetchFirstDeposits",
    "deleteProp": "/prop",
    "publishContest": "/contests/publish-contest",
    "similarPublish": "/adminContest/similar/publish",
    "saveVersusProp": "/prop/saveVersusProp",
    "forgetPassword": "/admin/send-reset-password-link-admin",
    "contestPayout": "/adminContest/contestPayout",
    "getEditContest": "/adminContest/getEditContest",
    "isContestPayoutExist": "/adminContest/isContestPayoutExist",
    "featuredUnfeatured": "/contests/featured",
    "getEntrantsContestList": "/adminContest/get-entrants-list-contest",
    "getEntrantsContestListArchive": "/adminContest/get-entrants-list-contest-archive",
    "searchByContestName": "/contests/contest-search",
    "contestDetail": "/adminContest/getEditContest",
    "cancelContest": "/adminContest/cancle-contest",
    "getRegisteredUsers": "/adminContest/get-registered-users",
    "getDeleteUserRequests": "/adminContest/get-delete-user-requests",
    "contestListingByUserId": "/adminContest/contest-listing-by-user-id",
    "averageAmountByleauge": "/adminContest/avg-amount-by-fan-id",
    "popularPlayers": "/adminContest/`list-of-`popular-players",
    "sendNotification": "/adminContest/admin-custom-notification",
    "saveContent": "/adminContest/terms-and-condition-pdf-upload",
    "privacyUpload": "/adminContest/privacy-policy",
    "deleteRegisterdFan": "/adminContest/delete-user",
    "rejectDeleteUserRequest": "/adminContest/reject-delete-user-request",
    "changePassword": "/admin/change-password",
    "editContest": "/adminContest/postEditContest",
    "avgGameTime": "/adminContest/insert-league-game-time",
    "getGameTime": "/adminContest/get-league-game-time",
    "icePropDescription": "/adminContest/insert-ice-prop-description",
    "getIcePropDescription": "/adminContest/get-ice-prop-description",
    "getPlayerListByContestId": "/adminContest/get-player-list-by-contest-id",
    "propInactiveFlag": "/adminContest/prop-active-inactive-flag",
    "getCSV": "/adminContest/get-csv",
    "getContent": "/adminContest/get-content-mangement",
    "howtoplayUpload": "/adminContest/how-to-play",
    "updateAdminContestPropParameter": "/adminContest/updateAdminContestPropParameter",
    "prizePayout": "/users/user-eligible-refund-listing",
    "prizeoutAccept": "/accept",
    "prizeoutReject": "/reject",
    "paymentSessionPayout": "/payment/session/payout",
    "logCheckPayout": "/payment/logCheckPayout",
    "scriptTagPayout": "/payment/script-tag-payout",
    "addWalletBalance": "/payment/admin-userwallet-fund-addition",
    "initCheckPayout": "/payment/session/payout/init-cheque",
    "createHouseProp": "/houseProp/admin/createProp",
    "fetchHouseUserContestsUpcoming": "/houseProp/admin/fetchHouseUserContestsUpcoming",
    "fetchHouseUserContestsLive": "/houseProp/admin/fetchHouseUserContestsLive",
    "fetchHouseUserContestsHistory": "/houseProp/admin/fetchHouseUserContestsHistory",
    "cancelHouseUserContest": "/houseProp/admin/cancelHouseUserContest",
    "unCancelHouseUserContest": "/houseProp/admin/unCancelHouseUserContest",
    "fetchHousePropsUpcoming": "/houseProp/admin/fetchHousePropsUpcoming",
    "fetchHousePropsLive": "/houseProp/admin/fetchHousePropsLive",
    "fetchHousePropsHistory": "/houseProp/admin/fetchHousePropsHistory",
    "fetchRecentHousePropsHistory": "/houseProp/admin/fetchRecentHousePropsHistory",
    "deactivateHouseProp": "/houseProp/admin/deactivateHouseProp",
    "disableHouseProp": "/houseProp/admin/disableHouseProp",
    "reactivateHouseProp": "/houseProp/admin/reactivateHouseProp",
    "reenableHouseProp": "/houseProp/admin/reenableHouseProp",
    "updateHousePropParameter": "/houseProp/admin/updateHousePropParameter",
    "fetchUnpublishedHouseProps": "/houseProp/admin/fetchUnpublishedHouseProps",
    "deleteHouseProp": "/houseProp/admin/deleteHouseProp",
    "publishUnpublishedHouseProps": "/houseProp/admin/publishUnpublishedHouseProps",
    "getUserPromoCodes": "/adminContest/getUserPromoCodes",
    "userListByName": "/users/user-search-list",
    "userListByPromo": "/users/user-search-list-by-promocode-and-date",
    "getAssignableContestTickets": "/tickets/getAssignableContestTickets",
    // "getAssignableHouseTickets": "/tickets/getAssignableHouseTickets",
    "getAssignableHouseTickets": "/tickets/getHouseTickets",
    "createTicket": "/tickets/createTicket",
    "createHouseTicket": "/tickets/createHouseTicket",
    "assignContestTickets": "/tickets/assignUserContestTickets",
    "assignHouseTickets": "/tickets/assignUserHouseTickets",
    "getUserContestTickets": "/tickets/getUserContestTickets",
    "getUserHouseTickets": "/tickets/getUserHouseTickets",
    "disableUserContestTicket": "/tickets/disableUserContestTicket",
    "getAllContestTickets": "/tickets/getContestTickets",
    "getAllHouseTickets": "/tickets/getHouseTickets",
    "getUserTicketsByContestTicket": "/tickets/userTicketsByContestTicket",
    "getUserTicketsByHouseTicket": "/tickets/userTicketsByHouseTicket",
    "getUserUserRoles": '/users/getUserUserRoles',
    "createUserUserRole": '/users/createUserUserRole',
    "deleteUserUserRole": '/users/deleteUserUserRole'
}



export const MICROSERVICE_APIS = {
    // 'nameOfServerlessService': 'Base URL for service API',
    // ...
};

// operating in prod environment
if (SERVER_URL === 'https://api.roobetpicks.com'){
    MICROSERVICE_APIS['service-adminsite-props'] = 'https://exd7g37h9f.execute-api.us-east-1.amazonaws.com/prod/';

// operating in staging environment
} else if (SERVER_URL === 'https://api.roobetpicksstaging.com') {
    MICROSERVICE_APIS['service-adminsite-props'] = 'https://dipu3p7i5g.execute-api.us-east-1.amazonaws.com/staging/';

// operating in development/local environment
}else{
    // MICROSERVICE_APIS['service-adminsite-props'] = 'https://exd7g37h9f.execute-api.us-east-1.amazonaws.com/prod/';
    MICROSERVICE_APIS['service-adminsite-props'] = 'http://localhost:3005/';
    // MICROSERVICE_APIS['service-adminsite-props'] = 'https://dipu3p7i5g.execute-api.us-east-1.amazonaws.com/staging/';
}
